<template>
  <ul class="stats">
    <li v-for="(item, index) in list">
      <span class="stats__id">{{ getIndex(index + 1) }}</span>
      <IconComponent v-bind="item.icon" />
      <span class="stats__title">{{ item.title }}</span>
      <span class="stats__subtitle">{{ item.subtitle }}</span>
    </li>
  </ul>
</template>
<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "StatsComponent",
  props: {
    list: Array,
  },
  methods: {
    getIndex(index) {
      if (index < 10) {
        return "0" + index;
      }
      return index;
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
.stats {
  width 100%
  display grid
  align-items flex-start
  justify-content space-between
  grid-template-columns repeat(3, 1fr)
  gap 30px
  list-style none
  margin 0
  padding 0

  li {
    display grid
    justify-content center
    align-items center
    gap 10px

    .icon {
      width 60px
      height 60px
      margin auto

      svg path {
        stroke var(--green)
      }
    }
  }

  &__id {
    font-weight: 700;
    font-size: 0.75;
    line-height: 15px;
    text-align: center;
    color: var(--main);
  }

  &__title {
    font-weight: 700;
    font-size: 1.375em;
    line-height: 130%;
    color: var(--main);
    text-align center
  }

  &__subtitle {
    font-size: 1.125em;
    text-align: center;
    color: var(--main);
    opacity: 0.7;
  }
}
</style>
