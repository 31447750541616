<template>
  <section class="home-about">
    <h2 class="home-about__title">Любим что делаем<span class="green-dot">.</span></h2>
    <article class="home-about__body">
      <img src="/static/images/about.png" alt="Мы — команда профессионалов" class="home-about__image" />
      <div class="home-about__content">
        <h3 class="home-about__content-title">Мы — команда профессионалов</h3>
        <p class="home-about__content-text">
          Наша команда каждый день проделывает огромную работу, для того чтобы сделать ваши путешествия
          максимально интересными Мы добавляем в приложение только самые интересные локации и туры от местных
          гидов и бывалых путешественников Теперь больше не придется тратить половину путешествия на поисковик
          Путешествуй Туда - Сюда 🧭
        </p>
        <ul class="home-about__socials">
          <li>
            <a href="https://t.me/tudasudagid" target="_blank">
              <IconComponent category="default" name="telegram" />
            </a>
          </li>
          <li>
            <a href="https://youtube.com/playlist?list=PLdHC03DfL3dKH2b42ENxL9WlhAEdhAy0b" target="_blank">
              <IconComponent category="default" name="youtube" />
            </a>
          </li>
        </ul>
      </div>
    </article>
    <StatsComponent :list="stats" />
  </section>
</template>
<script>
import StatsComponent from "components/StatsComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "HomeAboutComponent",
  data() {
    return {
      stats: [
        {
          icon: {
            category: "linear",
            name: "magicpen",
          },
          title: "Удобство",
          subtitle: "Планируй свое путешествие быстро и удобно. Местный гид в твоем кармане",
        },
        {
          icon: {
            category: "linear",
            name: "device-message",
          },
          title: "Поддержка 24/7",
          subtitle:
            "По любому вопросу, сложностям, отзывам и предложениям, вы можете обратиться в нашу службу поддержки.\n" +
            "Поддержка и помощь пользователям осуществляется в удобном мессенджере Telegram.",
        },
        {
          icon: {
            category: "linear",
            name: "chart-21",
          },
          title: "Непрерывное развитие",
          subtitle:
            "Наша команда каждый день проделывает огромную работу, для того чтобы сделать ваши путешествия максимально интересными",
        },
      ],
    };
  },
  components: { IconComponent, StatsComponent },
};
</script>

<style lang="stylus">
.home-about {
  display grid
  justify-content center
  margin-bottom 100px
  +below(1100px) {
    margin-bottom 40px
  }

  &__title {
    font-weight: 800;
    font-size: 2.125em;
    text-align: center;
    color: var(--main);
    margin 0
    +below(768px) {
      font-size 1.8em
    }
  }

  &__body {
    display grid
    grid-template-columns 1fr 1fr
    align-items center
    grid-gap 80px
    padding 70px 100px 50px
    +below(1200px) {
      padding 30px 40px 40px
    }
    +below(960px) {
      grid-template-columns 1fr
    }
    +below(768px) {
      padding 20px
    }
    +below(560px) {
      padding 16px
    }
  }

  &__content {
    display grid
    align-items center
    grid-gap 30px
    z-index 1
  }

  &__content-title {
    font-weight: 700;
    font-size: 1.375em;
    color: var(--main);
    margin 0
  }

  &__content-text {
    font-weight: 400;
    font-size: 1.375em;
    line-height: 28px;
    color: var(--main);
    margin 0
  }

  &__socials {
    display flex
    align-items center
    margin 0
    padding 0
    list-style none
    gap 5px

    li {
      display flex
      align-items center
      justify-content center

      a {
        background: var(--gray);
        border: 1px solid var(--main-o3);
        border-radius: 100px;
        width 50px
        height 50px
        display inline-flex
        align-items center
        justify-content center

        .icon {
          width 20px
          height 20px

          svg path {
            fill var(--main)
          }
        }
      }
    }
  }

  .stats {
    padding 0 100px
    +below(1200px) {
      padding 40px
      display grid
      grid-template-columns repeat(2, 1fr)
      grid-gap 30px
    }
    +below(768px) {
      padding 20px
      grid-template-columns 1fr
    }
    +below(560px) {
      padding 16px
    }
  }

  &__image {
    border-radius 10px
    height 475px
    width 100%
    +below(960px) {
      display none
    }
  }

}
</style>
