<template>
  <section class="home-overview" id="advantages">
    <h2 class="home-overview__title">Почему выбирают<br />Туда - Сюда<span class="green-dot">.</span></h2>
    <StatsComponent :list="stats" />
    <div class="home-overview__slides">
      <div class="swiper" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in mockups" :key="i">
            <img :src="item.img" :alt="item.title" class="home-overview__slide-item" />
          </div>
        </div>
      </div>
    </div>
    <div class="home-overview__pagination"></div>
    <img
      src="/static/svg/default/overview-background.svg"
      alt="My Projects"
      class="home-overview__background"
    />
  </section>
</template>

<script>
import StatsComponent from "components/StatsComponent.vue";

export default {
  name: "HomeOverviewComponent",
  data() {
    return {
      stats: [
        {
          icon: {
            category: "linear",
            name: "map",
          },
          title: "Самые крутые локации",
        },
        {
          icon: {
            category: "linear",
            name: "route-square",
          },
          title: "Авторские маршруты",
        },
        {
          icon: {
            category: "linear",
            name: "game",
          },
          title: "Элементы геймификации",
        },
      ],
      mockups: [
        {
          img: "/static/images/image0.png",
          title: "0",
        },
        {
          img: "/static/images/image1.png",
          title: "1",
        },
        {
          img: "/static/images/image2.png",
          title: "2",
        },
        {
          img: "/static/images/image3.png",
          title: "3",
        },
        {
          img: "/static/images/image4.png",
          title: "4",
        },
        {
          img: "/static/images/image5.png",
          title: "5",
        },
        {
          img: "/static/images/image6.png",
          title: "6",
        },
        {
          img: "/static/images/image7.png",
          title: "7",
        },
        {
          img: "/static/images/image8.png",
          title: "8",
        },
      ],
      swiper: null,
    };
  },
  mounted() {
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        slidesPerView: 1.05,
        spaceBetween: 16,
        centeredSlides: true,
        pagination: {
          el: ".home-overview__pagination",
          dynamicBullets: true,
        },
        breakpoints: {
          350: {
            slidesPerView: 1.2,
            spaceBetween: 16,
          },
          430: {
            slidesPerView: 1.5,
            spaceBetween: 16,
          },
          520: {
            slidesPerView: 2,
            spaceBetween: 16,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          960: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1240: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1570: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        },
      });
      if (this.windowSize > 1570) {
        if (this.mockups.length >= 5) {
          this.swiper.slideTo(3);
        }
      } else if (this.windowSize > 1440) {
        if (this.mockups.length >= 4) {
          this.swiper.slideTo(2);
        }
      } else {
        if (this.mockups.length >= 3) {
          this.swiper.slideTo(2);
        }
      }
    });
  },
  components: { StatsComponent },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.home-overview {
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 100%
  position relative
  margin-bottom 100px
  +below(768px) {
    gap 20px
    margin-bottom 40px
  }

  &__background {
    absolute left bottom
    transform translateY(100%)
  }

  &__title {
    font-weight: 800;
    font-size: 2.125em;
    text-align: center;
    color: var(--main);
    margin 0
    +below(768px) {
      font-size 1.8em
    }
  }

  .stats {
    padding 0 100px
    max-width 1000px
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px
    margin-top 50px
    +below(1200px) {
      padding 40px
      grid-template-columns repeat(2, 1fr)
    }
    +below(768px) {
      padding 20px
      grid-template-columns 1fr
    }
    +below(560px) {
      padding 16px
    }
  }

  &__slides {
    position relative
    width 100%

    .swiper-slide {
      width auto
      display inline-flex
      justify-content center
      align-items center
      height 100%
      transition var(--transition)
      padding 30px 0
      +above(1641px) {
        padding 50px 0
        &.swiper-slide-active {
          transform scale(1.1)
          padding 60px 0
        }
      }

      img {
        max-width 280px
        max-height 575px
        transition var(--transition)
        +above(1641px) {
          filter: drop-shadow(0 0 20px #0000003b);
        }
      }
    }
  }

  &__pagination {
    margin 0 auto
    left 0 !important
    transform none !important

    .swiper-pagination-bullet {
      width 20px
      height 20px
      background var(--main)
      opacity 0.3
    }

    .swiper-pagination-bullet-active {
      background var(--green)
      opacity 1
    }

  }
}
</style>
