<template>
  <section class="home-welcome">
    <img
      src="/static/svg/default/welcome-background.svg"
      alt="My Projects"
      class="home-welcome__background"
    />
    <div class="home-welcome__content">
      <h1 class="home-welcome__title">
        <span>Только самые крутые локации</span><br />
        для путешествий <br />
        в приложении: <br />«Туда-Сюда»
      </h1>
      <span class="home-welcome__subtitle">
        Планируй свое путешествие быстро и удобно. Местный гид в твоем кармане 🧭
      </span>
      <div class="home-welcome__apps">
        <a
          href="https://apps.apple.com/tr/app/tuda-suda/id6443678429"
          target="_blank"
          rel="nofollow"
          title="App Store"
        >
          <IconComponent category="default" name="app-store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.app.tudasuda&hl=ru&gl=US"
          target="_blank"
          rel="nofollow"
          title="Google Play"
        >
          <IconComponent category="default" name="google-play" />
        </a>
      </div>
    </div>
    <img src="/static/images/image-1.png" class="home-welcome__image" alt="My Projects" />
    <div class="home-welcome__floating-arrow">
      <IconComponent category="default" name="welcome-arrow" />
    </div>
  </section>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
// import PhoneMockupComponent from "components/PhoneMockupComponent.vue";

export default {
  name: "HomeWelcomeComponent",
  components: {
    // PhoneMockupComponent,
    IconComponent,
  },
};
</script>

<style lang="stylus">
.home-welcome {
  display grid
  align-items center
  padding 100px
  padding-top 190px
  position relative
  grid-gap 30px
  grid-template-columns 3fr 2fr
  @media (min-height: 890px) and (min-width 1024px) {
    height 100vh
  }
  +below(1200px) {
    padding 40px
    padding-top 140px
  }
  +below(1024px) {
    grid-template-columns 1fr
  }
  +below(768px) {
    padding 20px
    padding-top 100px
    padding-bottom 40px
  }
  +below(560px) {
    padding 16px
    padding-top: 70px;
    padding-bottom 40px
  }

  &__background {
    absolute right top
    height auto
    object-fit contain
    object-position right top
    +below(768px) {
      opacity 0.3
    }
  }

  &__content {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-end
    height 100%
    grid-column 1
    grid-row 1
    z-index: 1;
  }

  &__title {
    font-weight: 800;
    font-size: 3.375em;
    line-height: 130%;
    text-transform: uppercase;
    color var(--main)
    max-width 710px
    margin 0
    +below(768px) {
      font-size: 2.5em;
    }
    +below(560px) {
      font-size: 2em;
    }
    +below(360px) {
      font-size: 1.7em;
    }

    span {
      text-decoration-line: underline;
      text-decoration-color var(--green)
      color var(--green)
    }
  }

  &__subtitle {
    font-weight: 400;
    font-size: 1.125em;
    line-height: 155%;
    color: var(--main);
    max-width 480px
    padding 30px 0 50px
    +below(768px) {
      padding 10px 0 20px
    }
  }

  &__apps {
    display flex
    gap 10px

    a {
      width auto
      height 56px
      +below(768px) {
        height: 40px;
      }

      .icon {
        width 100%
        height 100%
      }
    }
  }

  &__note {
    padding 15px 0 0
    font-weight: normal;
    font-size: 0.875em;
    line-height: 18px;
    text-align: center;
    color: var(--main);
    display flex
    flex-wrap wrap
    gap 5px

    span {
      opacity: 0.5;
    }

    a {
      color: var(--main);
    }
  }

  &__image {
    position relative
    display flex
    align-items center
    width 100%
    height 100%
    justify-content space-between
    grid-column 2
    grid-row 1 / 3
    grid-gap 30px
    object-fit contain
    +below(1024px) {
      display none
    }

    .mockup {
      max-width 280px
      max-height 575px

      &:first-child {
        margin-bottom auto
        filter: drop-shadow(30px 40px 30px #0000003b);
      }

      &:last-child {
        margin-top auto
        filter: drop-shadow(0 0 20px #0000003b);
        +below(1400px) {
          display none
        }
      }
    }
  }


  &__reviews {
    display grid
    gap 10px
    grid-column 1
    grid-row 2
  }

  &__reviews-circles {
    list-style none
    display inline-flex
    align-items flex-start
    justify-content flex-start
    padding 0
    margin 0

    li {
      border: 2px solid var(--gray);
      box-shadow 0 3px 5px rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      overflow hidden
      display flex
      justify-content center
      align-items center

      &:not(:last-child) {
        margin-right -10px
      }

      img {
        width 100%
        height 100%
        object-fit cover
      }
    }
  }

  &__reviews-data {
    display inline-flex
    justify-content flex-start
    align-items center

    .icon {
      width 18px
      height 18px
      relative top -3px

      svg path {
        fill var(--orange)
        stroke var(--orange)
      }
    }
  }

  &__reviews-average {
    margin 0 3px 0 7px
    font-weight: 400;
    font-size: 1.125em;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--main);
    display flex
    align-items center
  }

  &__reviews-count {
    font-weight: 400;
    font-size: 1.125em;
    line-height: 100%;
    color: var(--main);
    opacity: 0.5;
    display flex
    align-items center
  }

  &__floating-arrow {
    absolute left bottom 100px
    width 100%
    display flex
    justify-content center
    +below(768px) {
      display none
    }

    .icon {
      width 8px
      height 61px
    }
  }
}
</style>
