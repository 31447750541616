<template>
  <div class="page page-home">
    <HomeWelcomeComponent />
    <HomeOverviewComponent />
    <HomeSubjectsComponent v-if="showSubjects" />
    <HomeAboutComponent />
    <!--    <HomeTariffsComponent />-->
    <HomeReviewsComponent />
  </div>
</template>

<script>
import HomeWelcomeComponent from "./components/HomeWelcomeComponent.vue";
import HomeOverviewComponent from "./components/HomeOverviewComponent.vue";
import HomeAboutComponent from "./components/HomeAboutComponent.vue";
// import HomeTariffsComponent from "./components/HomeTariffsComponent.vue";
import HomeReviewsComponent from "./components/HomeReviewsComponent.vue";
import SUBJECTS from "gql/subjects.graphql";

export default {
  name: "HomePage",
  data() {
    return {
      showSubjects: false,
    };
  },
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: SUBJECTS,
        context: {
          headers: {
            Authorization: "Bearer " + store.state.apollo_token,
          },
        },
      })
      .then(({ data }) => {
        store.state.subjects = data.subjects;
      });
  },
  mounted() {
    this.$nextTick(() => {
      this.showSubjects = true;
    });
  },
  components: {
    HomeReviewsComponent,
    // HomeTariffsComponent,
    HomeAboutComponent,
    HomeOverviewComponent,
    HomeWelcomeComponent,
    HomeSubjectsComponent: () => import("./components/HomeSubjectsComponent.vue"),
  },
};
</script>

<style lang="stylus">
.page-home {
  overflow hidden
}
</style>
