<template>
  <section class="home-reviews">
    <div class="home-reviews__top-border">
      <svg
        height="143"
        preserveAspectRatio="none"
        viewBox="0 0 1728 142"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M760.5 3.60858C559.3 -16.3914 169.667 51.6086 0 88.1086V142.109H1728V26C1645 38.1667 1437.8 61.5086 1275 57.1086C1071.5 51.6086 1012 28.6086 760.5 3.60858Z"
          fill="#283246"
        />
      </svg>
    </div>
    <div class="home-reviews__container">
      <div class="home-reviews__faq" id="faq">
        <h2 class="home-reviews__title">Вопросы и ответы<span class="green-dot">.</span></h2>
        <ul class="faq faq--white">
          <li class="faq__item" v-for="(faq, i) in faqs" :key="i">
            <button type="button" class="faq__header" @click="toggleFaq(i)">
              <span>{{ faq.title }}</span>
              <IconComponent category="default" :name="activeFaq === i ? 'minus' : 'plus'" />
            </button>
            <div class="faq__body" v-if="activeFaq === i">{{ faq.text }}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="home-reviews__bottom-border">
      <svg
        height="143"
        preserveAspectRatio="none"
        viewBox="0 0 1728 143"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M967.5 138.5C1168.7 158.5 1558.33 90.4998 1728 53.9998V-0.000244141H0V116.108C83 103.942 290.2 80.5998 453 84.9998C656.5 90.4998 716 113.5 967.5 138.5Z"
          fill="#283246"
        />
      </svg>
    </div>
  </section>
</template>
<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "HomeReviewsComponent",
  data() {
    return {
      activeFaq: null,
      faqs: [
        {
          title: "На каких платформах доступно приложение?",
          text:
            "Приложение доступно на iOS и Android. Вы можете найти нас в App Store, Google Play.",
        },
        {
          title: 'Чем приложение "Туда Сюда" отличается от аналогов?',
          text: "В отличии от схожих приложений, также будут применены элементы геймификации, что сделает путешествие пользователей более увлекательными.",
        },
        {
          title: 'Куда обращаться, если у меня возникли сложности, ошибки, или пожелания по улучшению?',
          text: "В таком случае, вы можете перейти в кабинет, и найти раздел \"Обратная связь\". При нажатии на на него, вас перенесет на чат со Службой поддержки в удобный и привычный мессенджер Telegram.",
        },
        {
          title: 'Будет ли расширение функционала?',
          text: "Наша команда каждый день проделывает огромную работу, для того чтобы сделать ваши путешествия максимально интересными",
        },
      ],
      reviews: [
        {
          fio: "Kamil Kamilov ",
          stars: 5,
          text: "Долго ждал появления этого приложения. Первые впечатления очень хорошие. Очень удобный в использовании, будем дальше пользоваться.",
        },
        {
          fio: "kotolenko",
          stars: 5,
          text: "Ну идея просто супер ребята 👍🏻👍🏻, все легко и понятно, мне очень нравится, на самом деле каждого сотрудника вижу, кто чем занят в течении дня, видно проект только начал свой путь, будем ждать стабильных обновлений 🤝",
        },
        {
          fio: "Анилайк",
          stars: 5,
          text: "Очень удобно и понятно, спасибо разработчикам за такое классное приложение. Подойдёт для каждого кто планирует в этом мире хоть что-то.",
        },
        {
          fio: "5565прнт",
          stars: 5,
          text: "Очень полезное приложение, прям то, чего действительно не хватало, спасибо большое 👍🏻",
        },
      ],
      swiper: null,
    };
  },
  mounted() {
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        slidesPerView: 1,
        autoHeight: true,
      });
    });
  },
  methods: {
    toggleFaq(i) {
      this.activeFaq = this.activeFaq === i ? null : i;
    },
  },
  components: { IconComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/faq.styl"
.home-reviews {
  display flex
  flex-direction column

  &__top-border,
  &__bottom-border {
    width 100%
    display flex

    svg {
      width 100%
    }
  }

  &__container {
    display flex
    flex-direction column
    gap 100px
    justify-content center
    background var(--main)
  }

  &__reviews {
    display flex
    flex-direction column
    justify-content center
    max-width 1280px
    margin auto
    +below(1440px) {
      max-width 850px
    }
    +below(850px) {
      margin initial
    }
  }

  &__title {
    font-weight: 700;
    font-size: 2.125em;
    line-height: 130%;
    text-align: center;
    color: var(--gray);
    margin 0
    +below(768px) {
      font-size 1.8em
    }
  }

  &__rating-data {
    display inline-flex
    justify-content center
    align-items center
    margin-top 30px
    margin-bottom 70px

    .icon {
      width 18px
      height 18px
      relative top -3px

      svg path {
        fill var(--orange)
        stroke var(--orange)
      }
    }
  }

  &__rating-title {
    font-weight: 700;
    font-size: 1.125em;
    line-height: 100%;
    color: var(--gray);
    margin-right 15px
  }

  &__rating-average {
    margin 0 3px 0 7px
    font-weight: 400;
    font-size: 1.125em;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--white);
    display flex
    align-items center
  }

  &__rating-count {
    font-weight: 400;
    font-size: 1.125em;
    line-height: 100%;
    color: var(--gray);
    opacity: 0.5;
    display flex
    align-items center
  }

  &__item {
    display flex
    flex-direction column
    justify-content center
    text-align center
    max-width 770px
    width 100%

    &-image {
      width 60px
      height 60px
      border-radius 100%
      object-fit cover
      margin 0 auto 10px
      border: 2px solid var(--main-light);
    }

    &-title {
      font-size: 1.125em;
      color: var(--white);
    }

    &-stars {
      display flex
      align-items center
      justify-content center
      gap 5px
      margin 10px 0 30px

      .icon {
        width 20px
        height 20px

        &.filled svg path {
          fill var(--orange)
        }

        svg path {
          stroke var(--orange)
        }
      }
    }

    &-text {
      font-size: 1.375em;
      line-height: 150%;
      text-align: center;
      color: var(--gray);
    }
  }

  &__slider {
    padding 0 100px
    position relative
    +below(850px) {
      padding 0 50px
    }

    &-image {
      absolute left top right bottom
      margin auto
    }

    .swiper-slide {
      display flex
      justify-content center
    }

    &-arrow {
      width 50px
      height 50px
      background var(--gray)
      display inline-flex
      justify-content center
      align-items center
      border-radius 100%
      border none
      margin auto

      &:first-child {
        absolute left top bottom
      }

      &:last-child {
        absolute right top bottom
      }
    }
  }

  &__faq {
    max-width 905px
    justify-content center
    margin auto
    width 100%
    padding 0 16px

    .faq {
      width 100%
    }
  }
}
</style>
